import { jwtDecode } from "jwt-decode"
import type { FirebaseAuthToken } from "~~/stores/auth.store"

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const token = useCookie("auth_token")

  // User is not authenticated
  if (!token.value) {
    return navigateTo("/?redirect=" + to.path)
  }

  const decodedToken = jwtDecode<FirebaseAuthToken>(token.value)

  // User is not logged in to any organization
  if (decodedToken.claims?.organizations.length === 0) {
    const searchParams = new URLSearchParams()
    searchParams.set("switch_organization", "true")
    searchParams.set("redirect", to.path)
    return navigateTo(`/?${searchParams}`)
  }
})
